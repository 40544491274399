<template lang="">
  <modal-padrao
    ref="modal-input"
    :max-width="1000"
    :titulo="$t('componentes.input_instrumento.titulo_modal')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          v-model="filtro.codigoTag"
          :label="$t('componentes.input_instrumento.codigo')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.nomeTipoInstrumento"
          :label="$t('componentes.input_instrumento.nome')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.faixa"
          :label="$t('componentes.input_instrumento.faixa')"
          class="col-12 col-md-4"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      @paginar="listar"
      @selecionarTodos="selecionarTodos"
    >
      <template v-slot:item.data-table-select="{ item }">
        <v-simple-checkbox
          color="primary"
          :value="verificarSelecionado(item.item)"
          :disabled="verificarExistente(item.item)"
          @input="selecionarInstrumento(item.item)"
        />
      </template>
    </tabela-padrao>
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  props: {
    instrumentosExistentes: { type: Array, default: () => [] },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      instrumentoAdicionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('componentes.input_instrumento.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('componentes.input_instrumento.tabela.tipo'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  mounted() {
    this.listar();
  },
  methods: {
    selecionarTodos(evento){
      if(evento.value)
        this.inserirTodos(evento.items)
      else
        this.removerTodos(evento.items)
    },
    inserirTodos(instrumentos){
      instrumentos?.forEach(instrumento => {
        if (!this.instrumentoAdicionados
        .some(el => el.id == instrumento.id))
        {
          if(this.verificarExistente(instrumento)) return;
          this.instrumentoAdicionados.push(instrumento);
        }
      });
    },
    removerTodos(instrumentos){
      instrumentos?.forEach(instrumento => {
        this.excluir(instrumento);
      });
    },
    selecionarInstrumento(instrumento) {
      if (this.instrumentoAdicionados
        .some(el => el.id == instrumento.id))
          this.excluir(instrumento);
      else
        this.instrumentoAdicionados.push(instrumento);
    },
    verificarExistente(instrumento){
      return this.instrumentosExistentes
        .some(el => el.id === instrumento.id);
    },
    excluir: function (instrumento) {
      if(this.instrumentosExistentes
        .some(el => el.id === instrumento.id)) return;
      this.instrumentoAdicionados
        .splice(this.instrumentoAdicionados.indexOf(instrumento), 1);
    },
    verificarSelecionado(instrumento) {
      if(this.instrumentosExistentes.some(el => el.id === instrumento.id)) return true;
      return this.instrumentoAdicionados.some(el => el.id === instrumento.id)
    },
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    abrirModal() {
      this.$refs['modal-input'].abrirModal();
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      InstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      this.$emit('associarInstrumentos', this.instrumentoAdicionados)
    },
  },
};
</script>
